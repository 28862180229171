.section-title {
	margin-bottom: 60px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span {
		color: $theme-primary-color;
		font-size: 18px;
		font-weight: 500;
		text-transform: uppercase;
	}

	h2 {
		margin: 0;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font;
		font-size: 50px;
		font-weight: 700;
		line-height: 70px;



		@include media-query(991px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p {
		font-size: 18px;
		margin-top: 10px;
		@include media-query(991px) {
			font-size: 16px;
		}
	}

}