/*--------------------------------------------------------------
6. service-single-section
--------------------------------------------------------------*/

.service-single-section {

    @media(max-width:991px) {
        padding-bottom: 60px;
    }

    .service-single-wrap {
        img {
            width: 100%;
        }

        .service-single-content {
            .service-single-content-des {
                padding: 60px 0;

                @media(max-width:767px) {
                    padding: 35px 0;
                }

                h2 {
                    font-size: 33px;
                    font-family: $heading-font;
                    margin-bottom: 30px;
                    font-weight: 700;

                    @media(max-width:767px) {
                        font-size: 25px;
                        margin-bottom: 16px;
                    }
                }

                p {
                    margin-bottom: 30px;
                }

                .service-single-sub-img {
                    margin-top: 50px;

                    ul {
                        display: flex;
                        list-style: none;
                    }

                    li {
                        padding-left: 10px;
                        padding-right: 10px;

                        &:last-child {
                            padding-left: 10px;
                            padding-right: 0;
                        }
                    }
                }
            }

        }

        .solutions-section {
            h2 {
                font-size: 33px;
                font-family: $heading-font;
                margin-bottom: 30px;
                font-weight: 700;

                @media(max-width:575px) {
                    font-size: 25px;
                }
            }

            .solutions-item {
                background: $white;
                padding: 30px 25px;
                margin-bottom: 30px;
                box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);

                @media (max-width:1199px) {
                    padding: 25px 15px;
                }

                .solutions-icon {
                    .icon {
                        width: 50px;
                        height: 50px;

                        img {
                            width: 100%;
                        }
                    }
                }

                .solutions-text {
                    h2 {
                        font-size: 22px;
                        font-weight: 600;
                        margin: 20px 0;

                        @media (max-width:1199px) {
                            font-size: 19px;
                        }

                        a {
                            color: $dark-gray;

                            &:hover {
                                color: $theme-primary-color;
                            }
                        }
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .benefits-section {
            padding-top: 30px;

            h2 {
                font-size: 33px;
                font-family: $heading-font;
                margin-bottom: 30px;
                font-weight: 700;
            }

            .accordion {
                border-top: 1px solid #e1e1e1;
            }

            .accordion-item {
                border: 0;
                border-bottom: 1px solid #e1e1e1;

                .accordion-body {
                    padding-left: 0;
                    padding-right: 0;
                }

                button {
                    padding: 20px;
                    border: 0;
                    border-radius: 0;
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 20px;
                    color: $heading-color;
                    text-align: left;

                    &::after {
                        background: none;
                        font-family: "themify";
                        content: "\e622";
                        font-size: 15px;
                        transform: rotate(0deg);
                        font-weight: 700;
                    }


                    &.collapsed {
                        color: #373b3e;

                        &::after {
                            content: "\e61a";
                        }
                    }

                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border-color: #e1e1e1;

                    }


                }

                .accordion-collapse {
                    border: 0;
                }
            }

            .accordion-button {
                background: transparent;
            }
        }
    }

}

.single-sidebar {
    padding-left: 20px;

    @media(max-width:991px) {
        padding-left: 0;
        margin-top: 60px;
    }

    .widget {
        padding: 30px 40px;
        border: 1px solid #e1e1e1;
        margin-bottom: 40px;

        @media(max-width:1200px) {
            padding: 20px;
        }

        h2 {
            font-size: 25px;
            font-family: $heading-font;
            position: relative;
            text-align: center;
            display: block;
            margin-bottom: 30px;
            font-weight: 700;

        }

        ul {
            list-style: none;
        }

    }

    .service-widget {
        ul {

            li {
                a {
                    display: block;
                    font-size: 16px;
                    color: $white;
                    font-weight: 500;
                    padding-left: 30px;
                    position: relative;
                    margin-bottom: 5px;
                    background: #0028feab;
                    padding: 8px 0;
                    padding-left: 40px;

                    &:before {
                        position: absolute;
                        left: 20px;
                        top: 50%;
                        content: "";
                        background: $white;
                        width: 10px;
                        height: 3px;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        background: $theme-primary-color-s3;
                    }


                }

                &:last-child {
                    a {
                        margin-bottom: 5px;
                    }
                }

            }
        }
    }

    .newsletter-widget {
        p {
            font-size: 20px;
            font-family: $heading-font;
            color: $dark-gray;
            text-align: center;
        }

        span {
            font-size: 15px;
            color: $cyan;

            a {
                color: $dark-gray;
                font-weight: 700;

                &:hover {
                    color: $theme-primary-color;
                }
            }
        }

        .form {
            input {
                border: 0;
                display: block;
                width: 100%;
                height: 50px;
                @include rounded-border(50px);
                border: 1px solid #e1e1e1;
                padding: 15px;
                text-align: center;

                &:focus {
                    box-shadow: none;
                }
            }

            button {
                background: #0028feab;
                width: 100%;
                height: 50px;
                @include rounded-border(50px);
                text-align: center;
                color: $white;
                border: none;
                margin: 20px 0;
            }

        }
    }

    .instagram-widget {

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                -ms-flex: 0 0 33.33%;
                -webkit-box-flex: 0;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }

    }

    .contact-widget {
        border: 0;
        background: $theme-primary-color;

        h2 {
            font-size: 36px;
            font-weight: 700;
            text-align: left;
            color: $white;
            margin-bottom: 20px;
        }

        p {
            color: $white;
            font-size: 18px;
        }


        a {
            display: inline-block;
            padding: 10px 20px;
            font-size: 18px;
            color: $white;
            padding-right: 90px;
            position: relative;
            margin-top: 10px;
            background: $theme-primary-color;

            &::before {
                font-family: "themify";
                content: "\e628";
                font-size: 18px;
                position: absolute;
                right: 15px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
}