/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');



// fonts
$base-font-size: 15;
$base-font: 'Inter', sans-serif;
$heading-font:'DM Sans', sans-serif;

// color
$dark-gray: #000B47;//
$body-color: #687693;
$white: #fff;
$black: #000;
$cyan: #848892;

$theme-primary-color: #233FD6;//
$theme-primary-color-s2: #FBB132;//
$theme-primary-color-s3: #F97D7F;//
$theme-primary-color-s4: #7DC370;//
$theme-primary-color-s5: #8956B3;//
$body-bg-color: #fff;
$section-bg-color: #f2f2f280;
$text-color: #525252;//
$heading-color: $dark-gray;
$border-color: #3F3C4D;//
$border-color-s2: #D9D9D9;//