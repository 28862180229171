// page-title
.wpo-page-title {
	background: url(../../images/page-title.jpg) no-repeat center top / cover;
	min-height: 450px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;
	padding-top: 30px;

	@media(max-width:991px) {
		min-height: 350px;
	}

	@media(max-width: 767px) {
		min-height: 300px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $dark-gray;
		content: "";
		z-index: -1;
		opacity: .65;
	}

	.wpo-breadcumb-wrap {
		text-align: center;

		h2 {
			font-size: 60px;
			color: #fff;
			line-height: 60px;
			margin-bottom: 20px;
			font-weight: 600;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ol {
			padding-left: 0;
			margin-bottom: 0;

			li {
				display: inline-block;
				padding: 0px 5px;
				padding-right: 20px;
				color: #fff;
				position: relative;
				font-size: 20px;
				font-family: $base-font;

				&:last-child {
					padding-right: 0;
				}

				@media(max-width:767px) {
					font-size: 18px;
				}

				&:after {
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 8px;
					height: 8px;
					background: $white;
					border-radius: 50%;
				}

				&:last-child {
					span {
						color: #cbd4fd;
					}

					&:after {
						display: none;
					}
				}

				a {
					color: #fff;
					font-size: 20px;
					transition: all .3s;

					&:hover {
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}